@import '../../styles/LNQglobalVars';

.topNavigationHeader {
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 8px 24px;

    .userUtilityContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      vertical-align: middle;

      .dropdown {
        cursor: pointer;
      }

      .avatar {
        background-color: $grey_cc;
        margin-right: 10px;
      }

      .userName {
        color: #000;
        font-size: 14px;
      }
    }
  }
}
