@import '../../styles/LNQglobalVars';

.table {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
  }

  &__title {
    font-size: 14px;
    line-height: normal;
    font-weight: bold;
    color: $black;
    margin-bottom: 5px;
  }

  &__total {
    font-size: 12px;
    color: $grey_8c;
  }

  &__action-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    button {
      margin-left: 15px;
    }
  }
}

.lq-table {
  .ant-table-wrapper {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ant-table-thead th {
    font-size: 14px;
    font-weight: bold;
    color: $black;
    background-color: $grey_f6;
  }

  .ant-table-tbody td {
    color: $black;
  }
}
