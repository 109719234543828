@import '../../../styles/LNQglobalVars';
@import '../../../styles/common/table';

.company_table {
  :global {
    .ant-table-wrapper {
      border: 1px solid $grey_e8;
      border-bottom: none 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .ant-table-thead th {
      font-size: 14px;
      font-weight: bold;
      color: $black;
      background-color: $grey_f6;
    }

    .ant-table-tbody td {
      color: $black;
    }
  }

  .company_initials {
    background-color: $primary-color;
    color: #000;
    padding: 3px;
    border-radius: $borderRadius_3;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-right: 15px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
  }
}
